<template>
  <div>
    <div class="main-title">直播体验订单</div>
    <div class="content-list-page page-content">
      <div class="list-head-box"></div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l">
          <el-input
            v-model="searchData.uname"
            clearable
            placeholder="孩子姓名"
          />
        </span>
        <span class="margin-l-sm fl-l">
          <el-input v-model="searchData.phone" clearable placeholder="手机号" />
        </span>
        <span class="margin-l-sm fl-l">
          <el-input v-model="searchData.sn" clearable placeholder="支付单号" />
        </span>

        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          highlight-current-row
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80" />
          <el-table-column label="订单号" property="sn" />
          <el-table-column label="课程名称" property="live_course_name" />
          <el-table-column label="体验课名称" property="live_trial_name" />
          <el-table-column label="买家昵称" property="user_name" />
          <el-table-column label="手机号" property="phone" />
          <el-table-column label="孩子姓名" property="uname" />
          <el-table-column label="备注" property="remark" />
          <el-table-column label="状态" property="remark">
            <template slot-scope="scope">
              {{ getState(scope.row.status) }}
            </template>
          </el-table-column>
          <el-table-column label="商品价格" property="price" />
          <el-table-column label="支付金额" property="amount" />
          <el-table-column label="支付时间" property="pay_at" />
          <el-table-column label="创建时间" property="created_at" />
          <el-table-column label="更新时间" property="updated_at" />
          <el-table-column label="操作" property="address">
            <template slot-scope="scope">
              <span>
                <el-button
                  size="mini"
                  type="text"
                  @click="gainDetails(scope.row)"
                  >详情</el-button
                >
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="详情"
      width="60%"
    >
      <div v-loading="dialogVisibleLoading" class="dialog">
        <el-descriptions :column="3" border>
          <el-descriptions-item label="封面">
            <el-image :src="imageUrl + details.pic" style="width: 120px" />
          </el-descriptions-item>
          <el-descriptions-item label="体验课名称"
            >{{ details.live_trial_name }}
          </el-descriptions-item>
          <el-descriptions-item label="课程名称"
            >{{ details.live_course_name }}
          </el-descriptions-item>

          <el-descriptions-item label="订单号">
            <span>{{ details.sn }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="支付单号"
            >{{ details.pay_sn }}
          </el-descriptions-item>
          <el-descriptions-item label="状态"
            >{{ getState(details.status) }}
          </el-descriptions-item>
          <el-descriptions-item label="支付时间"
            >{{ details.pay_at }}
          </el-descriptions-item>
          <el-descriptions-item label="创建时间"
            >{{ details.created_at }}
          </el-descriptions-item>
          <el-descriptions-item label="关闭时间"
            >{{ details.close_at }}
          </el-descriptions-item>
          <el-descriptions-item label="商品总价"
            >{{ details.price }}
          </el-descriptions-item>
          <el-descriptions-item label="实际付款"
            >{{ details.amount }}
          </el-descriptions-item>
          <el-descriptions-item label="支付类型"
            >{{ details.pay_type === 1 ? "微信" : "支付宝" }}
          </el-descriptions-item>
          <el-descriptions-item label="买家"
            >{{ details.user_name }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "template-list",
  data() {
    return {
      tableData: [],
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      imageUrl: config.imageUrl,
      searchData: {},
      dialogVisible: false,
      details: {},
      dialogVisibleLoading: false,
    };
  },
  methods: {
    ...mapActions("liveOrder", ["orderList", "getOrder"]),
    async gainDetails({ id }) {
      try {
        this.dialogVisible = true;
        this.dialogVisibleLoading = true;
        const { data } = await this.getOrder({ id });
        this.details = data;
        this.dialogVisibleLoading = false;
      } catch (e) {
        this.dialogVisibleLoading = false;
        console.log(e);
      }
    },
    getState(row) {
      switch (row) {
        case 0:
          return "待支付";
        case 1:
          return "支付成功";
        case 2:
          return "支付失败";
        case 3:
          return "订单关闭";
      }
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    async getList() {
      try {
        this.loading = true;
        let from = {
          ...this.searchData,
          page: this.page,
          pageSize: this.pageSize,
        };
        const { data } = await this.orderList(from);
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
      this.loading = true;
      this.loading = false;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style></style>